<template>
  <div>
    <h1>Alle documenten</h1>

    <AdminButton :isSuperAdmin="isSuperAdmin" />

    <table v-if="FileResult">
      <tr v-for="file in FileResult.files" :key="file.id">
        <td>
          <a :href="file.url" target="_blank">{{ file.name }}</a>
        </td>
        <td v-if="isSuperAdmin">
          <a @click="deleteFile(file)">Wis</a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  defineComponent,
} from 'vue';
import { useQuery, useMutation } from 'villus';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  name: 'Files',

  components: { AdminButton },

  props: {
    isSuperAdmin: Boolean,
  },

  setup(props) {
    const Files = `
      query {
        files {
          id
          name
          url
        }
      }
    `;

    const { data: FileResult, execute: fetchFiles } = useQuery({ query: Files, cachePolicy: 'network-only' });

    const DeleteFile = `
      mutation DeleteFile($id:ID!) {
        deleteFile(id: $id) {
          id
        }
      }`;

    const { execute } = useMutation(DeleteFile);

    const deleteFile = (file) => {
      if (window.confirm('Zeker weten?')) { // eslint-disable-line no-alert
        execute({ id: file.id })
          .then(() => fetchFiles());
      }
    };

    return { FileResult, deleteFile };
  },
});
</script>

<style scoped lang="scss">
table{
  border-collapse:collapse;
}

tr:hover {
  background: #002244;
}

tr:hover a {
  color:white;
}

tr:hover {
  color:white;
  cursor:pointer;
}
</style>
